import { AxiosRequestConfig } from 'axios';

import { IS_CLIENT_SIDE } from 'constants/constants';
import { DataDogWrapper } from 'lib/dataDog';

export class Logger {
  public static log(message: any, ...rest: Array<any>) {
    DataDogWrapper.captureLog(message, ...rest);
  }

  public static warn(warn: any, ...rest: Array<any>) {
    DataDogWrapper.captureWarn(warn, ...rest);
  }

  public static error(error: any, ...rest: Array<any>) {
    DataDogWrapper.captureException(error, ...rest);
  }
}

export const logRequestMetadata = (
  config: AxiosRequestConfig,
  isMobile: boolean,
  status?: number,
) => {
  if (!config) {
    return console.log('No request data is presented');
  }

  if (IS_CLIENT_SIDE) {
    return;
  }

  const endTime = new Date().getTime();
  try {
    console.log(
      isMobile
        ? 'Request to Mobile API \n'
        : 'Request to CMS Proxy \n',
      `URL: ${config.baseURL}/${config.url};\n`,
      `Method: ${config.method};\n`,
      (status)
        ? `Status: ${status};\n`
        : 'Probably we faced some error;\n',
      `Headers: ${JSON.stringify(config.headers, null, 2)};\n`,
      (config.params)
        ? `Params: ${JSON.stringify(config.params, null)};\n`
        : '',
      (config.method?.toLowerCase() === 'post')
        ? `Body: ${JSON.stringify(config.data, null, 2)};\n`
        : '',
      // @ts-ignore
      `Duration: ${endTime - config.metadata.startTime} ms;`,
    );
  } catch (e) {
    console.error(
      'Error ocurred for Request to Mobile API \n',
      'Probably something wrong with headers/body/params \n',
      `URL: ${config.baseURL}/${config.url};\n`,
      `Method: ${config.method};\n`,
      // @ts-ignore
      `Duration: ${endTime - config.metadata.startTime} ms;`,
    );
  }
};
