/* eslint-disable max-classes-per-file */
import { ServerResponse } from 'http';

import { NextApiResponse } from 'next';

import { SET_COOKIE_HEADER } from 'constants/constants';

export class QueueElement {
  key: any;

  time: any;

  constructor(key: string, time: number) {
    this.key = key;
    this.time = time;
  }
}

export class TimeQueue {
  items: Array<any> = []

  isEmpty() {
    return this.items.length === 0;
  }

  size() {
    return this.items.length;
  }

  enqueue(key: string, time: number) {
    const queueElement: QueueElement = new QueueElement(key, time) || {};
    let contain = false;

    for (let i = 0; i < this.size(); i += 1) {
      if (this.items[i]?.time < queueElement.time) {
        this.items.splice(i, 0, queueElement);
        contain = true;

        break;
      }
    }

    if (!contain) {
      this.items.push(queueElement);
    }
  }

  dequeue() {
    if (this.isEmpty()) return null;

    return this.items.shift();
  }

  front() {
    if (this.isEmpty()) return null;

    return this.items[0];
  }

  rear() {
    if (this.isEmpty()) return null;

    return this.items[this.items.length - 1];
  }
}

export const applySetCookieHeader = (
  resp: NextApiResponse | ServerResponse,
  currentCookies?: Array<string> | string,
) => {
  if (!currentCookies) return;

  const setCookiesHeader = resp.getHeader(SET_COOKIE_HEADER);

  if (setCookiesHeader) {
    const newCurrentCookies = Array.isArray(currentCookies)
      ? [...currentCookies]
      : [currentCookies];

    const newCookiesArray = newCurrentCookies
      .concat(Array.isArray(setCookiesHeader)
        ? setCookiesHeader
        : `${setCookiesHeader}`);

    resp.setHeader(SET_COOKIE_HEADER, newCookiesArray);
  } else {
    resp.setHeader(SET_COOKIE_HEADER, currentCookies);
  }
};

export const returnValueIfExists = <T, >(key: string, value: T): Record<string, T> => {
  if (value === undefined || !key) {
    return {};
  }

  return { [key]: value };
};
