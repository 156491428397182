/* eslint-disable eqeqeq */
import {
  CustomerType,
  ItemsLayout,
  PsychicCardAppearance,
  Status,
} from 'constants/enums';
import { ClientOnly, WaitForLibrary } from 'lib/decorators';
import { ExponeaExtraData } from 'src/__generated__/graphqlTypes';
import {
  ExponeaExtraDataType,
  RightPsychic,
  SectionExtraDataType,
} from 'types/objectTypes';
import { Logger } from 'lib/logger';

const createImagesLinksFromSubstring = (link: string, lastIndex: number) => {
  const commonPath = link.substring(0, lastIndex);

  return [
    `${commonPath}/96/121`,
    `${commonPath}/192/242`,
    `${commonPath}/360/208`,
    `${commonPath}/720/416`,
    `${commonPath}/392/392`,
    `${commonPath}/196/196`,
  ];
};

const createImagesLinks = (link?: string) => {
  if (!link) {
    return [];
  }

  const { length } = link;

  if (link[length - 8] === '/') {
    return createImagesLinksFromSubstring(link, length - 8);
  }

  if (link[length - 7] === '/') {
    return createImagesLinksFromSubstring(link, length - 7);
  }

  if (link[length - 6] === '/') {
    return createImagesLinksFromSubstring(link, length - 6);
  }

  return [];
};

const getCustomerPrice = (basePrice: number, customerType: CustomerType) => {
  if (customerType !== CustomerType.NC) {
    return basePrice;
  }

  if (basePrice === 4.0 || basePrice === 5.0) {
    return 1.0;
  }

  if (basePrice === 6.5) {
    return 2.0;
  }

  if (basePrice === 7.5 || basePrice === 8.5) {
    return 4.0;
  }

  return basePrice;
};

export const mapExponeaRecommendations = (
  data: Array<any>,
  extraData: SectionExtraDataType = {},
  customerType: CustomerType,
) => {
  const { psychicCardAppearance } = extraData;
  const psychicsData = data
    .reduce((result, psychic) => {
      const currentPsychic = { tools: [], specialities: [], skills: [] } as any;
      Object.entries<any>(psychic).forEach(([key, value]) => {
        if (!value) {
          return;
        }

        if (key.toLowerCase().includes('topic')) {
          currentPsychic.specialities.push(value);
        }

        if (key.toLowerCase().includes('tools')) {
          currentPsychic.tools.push(value);
        }

        if (key.toLowerCase().includes('ability')) {
          currentPsychic.skills.push(value);
        }
      });

      // eslint-disable-next-line no-param-reassign
      result[psychic.ExtID] = currentPsychic;

      return result;
    }, { } as any);

  // @ts-ignore
  const psychics: Array<Partial<RightPsychic>> = data.map((psychic) => {
    const bioUrlParts = psychic.PsychicBioURL?.split('/') || [];
    const psychicBioURL = (bioUrlParts[bioUrlParts.length - 1]?.length > 0)
      ? `/${bioUrlParts[bioUrlParts.length - 1]}`
      : `/${psychic.LineName.toLowerCase()}-${psychic.ExtID}`;
    let photo: string;

    if (psychic.PsychicImageSource) {
      if (psychicCardAppearance === PsychicCardAppearance.SIMPLE
        || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL) {
        photo = psychic.PsychicImageSource.replace(/(\/.{3}){2}$/, '/196/196');
      } else {
        photo = psychic.PsychicImageSource.replace(/(\/.{3}){2}$/, '/720/416');
      }
    } else {
      photo = '';
    }

    const images = createImagesLinks(psychic.PsychicImageSource);
    const newDate = new Date();
    const serviceStartYear = (psychic.StartDate)
      ? new Date(psychic.StartDate).getFullYear()
      : new Date(newDate.setFullYear(
        newDate.getFullYear() - Math.floor(Math.random() * 4) - 1,
      )).getFullYear();
    const { tools, specialities, skills } = psychicsData[psychic.ExtID];
    const basePrice = parseFloat(psychic.RatePerMin);

    const overallScore = parseFloat(psychic.StarRating) || 4.00;
    const customerPrice = getCustomerPrice(basePrice, customerType);

    return ({
      extId: psychic.ExtID,
      basePrice: `$${basePrice.toFixed(2)}`,
      customerPrice: `$${customerPrice.toFixed(2)}`,
      psychicName: psychic.LineName,
      overallScore,
      phoneStatus: psychic.ActivePsychic == 'true' ? Status.AVAILABLE : Status.BUSY,
      chatStatus: psychic.ActivePsychic == 'true' ? Status.AVAILABLE : Status.BUSY,
      psychicImageUrl: photo,
      responses: (psychic.NumberOfTestimonials * 4) || 4,
      psychicBioURL,
      tools,
      serviceStartYear,
      totalReadings: (psychic.NumberOfTestimonials || 1),
      specialities,
      usp: `I'm working with ${specialities.length} specializations`,
      skills,
      images,
      estimatedWaitTime: Math.floor(Math.random() * 3),
      style: psychic.Style,
    });
  });

  return psychics;
};
export const getExponeaOnPsychicsLoaded = (
  sectionExtraData: SectionExtraDataType | undefined,
  customerType: CustomerType,
  setPsychics: (psychics: Array<RightPsychic> | null) => void,
  offLoadingState: () => void,
) => (exponeaPsychics: Array<any>) => {
  if (!exponeaPsychics?.length) {
    return offLoadingState();
  }

  // @ts-ignore
  const psychics: Array<Partial<RightPsychic>> = mapExponeaRecommendations(
    exponeaPsychics,
    sectionExtraData,
    customerType,
  );

  // @ts-ignore
  setPsychics(psychics);
};

export const updateExponeaExtraData = (
  extraData?: SectionExtraDataType,
  config?: ExponeaExtraData,
): SectionExtraDataType & ExponeaExtraDataType => {
  if (!extraData) {
    return {} as SectionExtraDataType;
  }

  try {
    const updatedExtraData = JSON.parse(JSON.stringify(extraData)) as SectionExtraDataType;

    if (!updatedExtraData.exponeaOptions) {
      updatedExtraData.exponeaOptions = {};
    }

    const {
      recommendationId,
      size,
      itemsLayout,
      psychicCardAppearance,
      resultPsychics,
      priceSort,
      rateSort,
    } = config || {};
    const { exponeaRecommendationId, exponeaSize } = updatedExtraData;

    if (recommendationId || exponeaRecommendationId) {
      updatedExtraData.exponeaOptions!.recommendationId = (recommendationId
        || exponeaRecommendationId) as string;
    }

    if (size || exponeaSize) {
      updatedExtraData.exponeaOptions!.size = (size || exponeaSize) as number;
    } else if (!updatedExtraData.exponeaOptions!.size) {
      updatedExtraData.exponeaOptions!.size = 10;
    }

    if (itemsLayout) {
      updatedExtraData.itemsLayout = itemsLayout as ItemsLayout;
    }

    if (psychicCardAppearance) {
      updatedExtraData.psychicCardAppearance = psychicCardAppearance as PsychicCardAppearance;
    }

    if (resultPsychics) {
      updatedExtraData.resultPsychics = resultPsychics;
    }

    if (priceSort) {
      updatedExtraData.priceSort = priceSort;
    }

    if (rateSort) {
      updatedExtraData.rateSort = rateSort;
    }

    return updatedExtraData;
  } catch (e) {
    Logger.error(e);

    return extraData;
  }
};

export class Exponea {
  @ClientOnly()
  @WaitForLibrary('exponea', 15)
  static async track(
    eventName: string,
    eventProperties?: Record<string, string | number | object | Array<any>>,
  ) {
    const { exponea } = window;

    if (!exponea) {
      return;
    }

    return new Promise((res) => {
      exponea.track(
        eventName,
        eventProperties,
        res,
        () => res(null),
      );
    });
  }

  @ClientOnly()
  @WaitForLibrary('exponea', 15)
  static async trackLink(
    selector: string,
    eventName: string,
    eventProperties?: Record<string, string | number | object | Array<any>>,
  ) {
    const { exponea } = window;

    if (!exponea) {
      return;
    }

    exponea.trackLink(
      selector,
      eventName,
      eventProperties,
    );
  }

  @ClientOnly()
  @WaitForLibrary('exponea', 15)
  static async identify(
    customerId: string,
    customerProperties?: Record<string, any>,
    immediate: boolean = false,
  ) {
    const { exponea } = window;

    if (!exponea) {
      return;
    }

    return new Promise((res) => {
      exponea.identify(
        { CustomerID: customerId },
        customerProperties,
        res,
        () => res(null),
        immediate,
      );
    });
  }
}
