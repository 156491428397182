/* eslint-disable no-param-reassign */
import { IS_CLIENT_SIDE } from 'constants/constants';

export const ClientOnly = () => (_: any, _1: string, descriptor: PropertyDescriptor) => {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: Array<any>) {
    if (!IS_CLIENT_SIDE) {
      return;
    }

    return originalMethod.apply(this, args);
  };
};
const stubFunction = () => {};

export const ShouldCall = (condition: boolean) => (
  _: any,
  __: string,
  descriptor: PropertyDescriptor,
) => {
  if (!condition) {
    descriptor.value = stubFunction;
  }

  return descriptor;
};

export const WaitForLibrary = (
  libraryName: string,
  counter: number = 10,
  waiting: number = 100,
) => (
  target: any,
  _1: string,
  descriptor: PropertyDescriptor,
) => {
  if (target.isLibLoaded === false) {
    return;
  }

  const originalMethod = descriptor.value;
  // eslint-disable-next-line no-undef
  let timer: null | NodeJS.Timeout = null;
  let index = 0;
  function newFunction(...args: Array<any>) {
    const lib = window[libraryName];

    if (lib || target.isLibLoaded === true) {
      if (timer) {
        clearTimeout(timer);
      }

      target.isLibLoaded = true;

      // @ts-ignore
      return originalMethod.apply(this, args);
    }

    if (index >= counter || target.isLibLoaded === false) {
      if (timer) {
        clearTimeout(timer);
      }

      target.isLibLoaded = false;

      return;
    }

    timer = setTimeout(() => newFunction(...args), waiting);
    index++;
  }

  descriptor.value = newFunction;
};
